<template>
  <div class="flex flex-col gap-6 bg-#1d3238 rounded-rt-5 px-2 py-5 relative">
    <div class="flex flex-col  gap-6 sticky top-0">
      <img
        class="ml-2 h-5 w-fit"
        src="/imgs/logo.svg"
        alt=""
      >
      <div class="flex flex-col gap-4">
        <Dropdown
          :ui="{ wrapper: 'flex flex-col gap-.75', root: 'flex flex-col gap-.75', headerContent: 'w-full text-left' }"
          :outside-click="false"
        >
          <template #dropdown-header-content="{ isShow }">
            <div
              class="parent flex items-center gap-2 p-2 hover:bg-#11222c text-4.25 font-500 leading-1 rounded-3 hover:text-white transition-colors relative"
              :class="isShow ? 'text-white bg-#11222c' : 'text-white/70'"
            >
              <span class="i-custom:call-bell text-5 text-firmennyy/70 transition-colors shrink-0 relative">
              </span>
              <ClientOnly fallback="Рестораны">
                {{ selectedRestaurant ? selectedRestaurant.title : 'Рестораны' }}
              </ClientOnly>
              <span
                class="text-3.5 text-firmennyy i-custom:arrow-down ml-auto parent-hover:rotate-180 transition-transform shrink-0"
                :class="{ 'rotate-180': isShow }"
              ></span>
              <div
                v-if="numberOfNewOrders > 0"
                class=" bg-red-500 text-white text-3 flex items-center justify-center rounded-full w-6 h-6"
              >
                {{ numberOfNewOrders }}
              </div>
            </div>
          </template>
          <ClientOnly>
            <div
              v-for="rest in restaurants"
              :key="rest.id"
              class="parent flex items-center gap-2 p-2 hover:bg-#11222c text-4.25 font-500 leading-1 rounded-3 hover:text-white transition-colors cursor-pointer"
              :class="selectedRestaurant?.id === rest.id ? 'text-white bg-#11222c' : 'text-white/70'"
              @click.stop="setSelectedRestaurant(rest), orderStore.setOrdersInNew([])"
            >
              <span
                class="i-custom:arrow-elbow-down-right text-5 text-firmennyy/70 transition-colors"
                :class="{ 'invisible': selectedRestaurant?.id !== rest.id }"
              ></span>
              {{ rest.title }}
            </div>
          </ClientOnly>
        </Dropdown>
        <div class="flex flex-col gap-.75">
          <NuxtLink
            to="/"
            class="uno-912l7q parent"
          >
            <span class="i-custom:cards-three parent-[&.router-link-active]:text-firmennyy-bright parent-[&.router-link-active]:i-custom:cards-three-fill parent-hover:text-firmennyy-bright parent-hover:i-custom:cards-three-fill text-5 text-firmennyy/70 transition-colors shrink-0"></span>
            Новые заказы
          </NuxtLink>
          <NuxtLink
            to="/history"
            :class="'[&.router-link-active>span]:clock-counter-clockwise-fill'"
            class="uno-912l7q parent"
          >
            <span class="i-custom:clock-counter-clockwise parent-[&.router-link-active]:text-firmennyy-bright parent-[&.router-link-active]:clock-counter-clockwise-fill parent-hover:text-firmennyy-bright parent-hover:clock-counter-clockwise-fill text-5 text-firmennyy/70 transition-colors shrink-0"></span>
            Выполненные заказы
          </NuxtLink>
          <NuxtLink
            to="/menu"
            :class="'uno-fuqtey'"
            class="uno-912l7q parent"
          >
            <span class="i-custom:text-columns parent-[&.router-link-active]:text-firmennyy-bright parent-[&.router-link-active]:i-custom:text-columns-fill parent-hover:text-firmennyy-bright parent-hover:i-custom:text-columns-fill text-5 text-firmennyy/70 transition-colors shrink-0"></span>
            Меню
          </NuxtLink>
          <NuxtLink
            to="/reviews"
            :class="'uno-dfu60o'"
            class="uno-912l7q parent"
          >
            <span class="i-custom:chat-circle-text parent-[&.router-link-active]:text-firmennyy-bright parent-[&.router-link-active]:i-custom:chat-circle-text-fill parent-hover:text-firmennyy-bright parent-hover:i-custom:chat-circle-text-fill text-5 text-firmennyy/70 transition-colors shrink-0"></span>
            Отзывы
          </NuxtLink>
          <NuxtLink
            to="/help"
            :class="'uno-9djlwp'"
            class="uno-912l7q parent"
          >
            <span class="i-custom:info parent-[&.router-link-active]:text-firmennyy-bright parent-[&.router-link-active]:i-custom:info parent-hover:text-firmennyy-bright parent-hover:i-custom:info text-5 text-firmennyy/70 transition-colors shrink-0"></span>
            Помощь
          </NuxtLink>
          <NuxtLink
            to="/settings"
            :class="'uno-p5jpsq'"
            class="uno-912l7q parent"
          >
            <span class="i-custom:gear parent-[&.router-link-active]:text-firmennyy-bright parent-[&.router-link-active]:i-custom:gear parent-hover:text-firmennyy-bright parent-hover:i-custom:gear text-5 text-firmennyy/70 transition-colors shrink-0"></span>
            Настройки
          </NuxtLink>
          <NuxtLink
            to="/reports"
            :class="'uno-9djlwp'"
            class="uno-912l7q parent"
          >
            <span class="i-custom:info parent-[&.router-link-active]:text-firmennyy-bright parent-[&.router-link-active]:i-custom:info parent-hover:text-firmennyy-bright parent-hover:i-custom:info text-5 text-firmennyy/70 transition-colors shrink-0"></span>
            Отчеты
          </NuxtLink>
          <NuxtLink
            to="/chronology"
            :class="'uno-9djlwp'"
            class="uno-912l7q parent"
          >
            <span class="i-custom:info parent-[&.router-link-active]:text-firmennyy-bright parent-[&.router-link-active]:i-custom:info parent-hover:text-firmennyy-bright parent-hover:i-custom:info text-5 text-firmennyy/70 transition-colors shrink-0"></span>
            Логи
          </NuxtLink>
          <NuxtLink
            to="/workers"
            :class="'uno-u1qtd0'"
            class="uno-912l7q parent"
          >
            <span class="i-custom:worker parent-[&.router-link-active]:text-firmennyy-bright parent-[&.router-link-active]:i-custom:worker-hover parent-hover:text-firmennyy-bright parent-hover:i-custom:worker-hover text-5 text-firmennyy/70 transition-colors shrink-0"></span>
            Работники
          </NuxtLink>
        </div>
      </div>
    </div>
    <ClientOnly>
      <button
        v-if="isLoggedIn"
        class="text-seryy/70 sticky bottom-3 text-4.25 font-500 leading-1 hover:text-seryy transition-colors flex items-center gap-2 pl-2 mt-auto"
        @click="userLogOut"
      >
        <span class="i-custom:sign-out"></span>
        Выйти
      </button>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useRestaurants } from '~/composables/partner/restaurants/useRestaurants';
import { useUserAuth } from '~/composables/user/useUserAuth';
import { orderStore as useOrderStore } from '~/stores/order-store';
const { restaurantList: restaurants, selectedRestaurant, setSelectedRestaurant } = useRestaurants();
const orderStore = useOrderStore();

const numberOfNewOrders = computed(() => orderStore.newOrders.length);

const { logOut, isLoggedIn } = useUserAuth();
const userLogOut = async () => {
  await logOut();
};
</script>
