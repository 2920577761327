import { default as chronologyRVmK8feeVqMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/chronology.vue?macro=true";
import { default as helpgAc2fEOEzSMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/help.vue?macro=true";
import { default as historyrWaDzoUdHYMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/history.vue?macro=true";
import { default as indexi8cbHPT4OsMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/index.vue?macro=true";
import { default as menuojkWYYLkxqMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/menu.vue?macro=true";
import { default as reports2DBjUsi11wMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/reports.vue?macro=true";
import { default as reviews809i2bEtFlMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/reviews.vue?macro=true";
import { default as settings4PHCwDvrmXMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/settings.vue?macro=true";
import { default as workerscAdTmZRa5UMeta } from "/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/workers.vue?macro=true";
export default [
  {
    name: chronologyRVmK8feeVqMeta?.name ?? "chronology",
    path: chronologyRVmK8feeVqMeta?.path ?? "/chronology",
    meta: chronologyRVmK8feeVqMeta || {},
    alias: chronologyRVmK8feeVqMeta?.alias || [],
    redirect: chronologyRVmK8feeVqMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/chronology.vue").then(m => m.default || m)
  },
  {
    name: helpgAc2fEOEzSMeta?.name ?? "help",
    path: helpgAc2fEOEzSMeta?.path ?? "/help",
    meta: helpgAc2fEOEzSMeta || {},
    alias: helpgAc2fEOEzSMeta?.alias || [],
    redirect: helpgAc2fEOEzSMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/help.vue").then(m => m.default || m)
  },
  {
    name: historyrWaDzoUdHYMeta?.name ?? "history",
    path: historyrWaDzoUdHYMeta?.path ?? "/history",
    meta: historyrWaDzoUdHYMeta || {},
    alias: historyrWaDzoUdHYMeta?.alias || [],
    redirect: historyrWaDzoUdHYMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/history.vue").then(m => m.default || m)
  },
  {
    name: indexi8cbHPT4OsMeta?.name ?? "index",
    path: indexi8cbHPT4OsMeta?.path ?? "/",
    meta: indexi8cbHPT4OsMeta || {},
    alias: indexi8cbHPT4OsMeta?.alias || [],
    redirect: indexi8cbHPT4OsMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/index.vue").then(m => m.default || m)
  },
  {
    name: menuojkWYYLkxqMeta?.name ?? "menu",
    path: menuojkWYYLkxqMeta?.path ?? "/menu",
    meta: menuojkWYYLkxqMeta || {},
    alias: menuojkWYYLkxqMeta?.alias || [],
    redirect: menuojkWYYLkxqMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/menu.vue").then(m => m.default || m)
  },
  {
    name: reports2DBjUsi11wMeta?.name ?? "reports",
    path: reports2DBjUsi11wMeta?.path ?? "/reports",
    meta: reports2DBjUsi11wMeta || {},
    alias: reports2DBjUsi11wMeta?.alias || [],
    redirect: reports2DBjUsi11wMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/reports.vue").then(m => m.default || m)
  },
  {
    name: reviews809i2bEtFlMeta?.name ?? "reviews",
    path: reviews809i2bEtFlMeta?.path ?? "/reviews",
    meta: reviews809i2bEtFlMeta || {},
    alias: reviews809i2bEtFlMeta?.alias || [],
    redirect: reviews809i2bEtFlMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/reviews.vue").then(m => m.default || m)
  },
  {
    name: settings4PHCwDvrmXMeta?.name ?? "settings",
    path: settings4PHCwDvrmXMeta?.path ?? "/settings",
    meta: settings4PHCwDvrmXMeta || {},
    alias: settings4PHCwDvrmXMeta?.alias || [],
    redirect: settings4PHCwDvrmXMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: workerscAdTmZRa5UMeta?.name ?? "workers",
    path: workerscAdTmZRa5UMeta?.path ?? "/workers",
    meta: workerscAdTmZRa5UMeta || {},
    alias: workerscAdTmZRa5UMeta?.alias || [],
    redirect: workerscAdTmZRa5UMeta?.redirect,
    component: () => import("/var/www/sakhfood/frontend_admin/sakhfood-admin-front-20240919063305/pages/workers.vue").then(m => m.default || m)
  }
]